import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Betero = () => {
  const imageFolder = "betero";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX"]}
        imageFolder={imageFolder}
        title="Sports betting platform"
        imageName="betero"
        subtitle="Betero"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `As a design agency, we had the privilege of collaborating on the Betero project, crafting a digital experience that aligns with their ambitious goals in the crypto betting space. Betero is a groundbreaking platform where users can place bets using cryptocurrency, offering unmatched convenience, security, and global accessibility. Our role was to translate their innovative vision into a sleek, user-centric design while ensuring the technical requirements of blockchain integration were seamlessly supported.`,

          `1.	Designing for Scalability and Security
We created a clean, intuitive interface that aligns with blockchain’s transparency and reliability. The scalable design ensures Betero can accommodate increasing user demands while maintaining high performance.`,
          `2.	Building a Crypto-Native Experience
With Betero’s custom token at the core, we designed features that highlight token-based benefits, such as exclusive betting opportunities and reduced fees. These elements were visually and functionally integrated to foster engagement and loyalty.`,
          `3.	Global Accessibility
The design focused on inclusivity, with straightforward navigation and seamless usability across devices. By eliminating regional and currency barriers, we ensured that the platform appeals to a worldwide audience.`,
          `4.	Pre-Launch Momentum
We supported Betero’s pre-launch success by designing an ecosystem that reflects trust and innovation, bolstered by the sale of over 9 million coins and significant venture capital backing.`,
          `5.	User-Friendly Interfaces
From live game tracking to simplified odds display, every feature was designed for intuitive use, ensuring even first-time users can easily engage with the platform.`,
          `Through a combination of strategic design, seamless user experience, and a strong understanding of the crypto betting market, we helped position Betero as a leader in the industry, ready to scale and innovate.
          `,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["betero-1", "betero-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="User research"
        paragraphs={[
          `We began by studying Betero’s target audience, which ranged from experienced crypto users to traditional bettors exploring cryptocurrency for the first time. This dual audience required a design that was:`,
`1.	Approachable for first-time users: Simplified flows for onboarding, betting, and wallet interactions.`,
`2.	Powerful for advanced users: Features like detailed betting stats, live updates, and real-time tracking.`,
`To address both, we created user personas that highlighted pain points like unclear betting odds, confusing navigation, and friction in crypto transactions.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Betting process"
        paragraphs={[
          `A key challenge was simplifying the betting process while retaining depth for experienced users. Here’s how we approached this:`,
          `1.	Clear Navigation: Tabs like Score, Statistics, Lineups, Timeline allow users to explore all game details without overwhelming them.`,
          `2.	Interactive Bet Slip: Designed to dynamically update as users add bets, showing total odds, potential winnings, and options like “Single,” “Parlay,” or “System” betting. This ensures transparency and user control.`,
          `3.	Live Updates: Real-time odds, game tracking, and stats make the experience immersive and engaging for live bettors.
          `,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-4", "betero-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Visual hierarchy"
        paragraphs={[
          `The design leverages a clean, modern layout to direct user attention to key actions:`,
`1.	Color Palette: The dark theme reduces eye strain, while green (for action), blue (for active states), and yellow/red (for game stats and alerts) create intuitive visual cues. For example, green buttons like “Deposit” or “Place Bet” stand out as actionable steps.`,
`2.	Typography: We chose Proxima Nova for its clean lines and versatility, ensuring legibility on both mobile and desktop. Larger font sizes for critical information like odds and game results help users focus.`,
`3.	Whitespace: Generous spacing ensures the interface feels uncluttered, even with detailed stats and multiple betting options visible.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["betero-6", "betero-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Simplifying crypto"
        paragraphs={[
          `For many users, betting with crypto can be intimidating. We designed Betero’s crypto wallet and transaction flows to be as intuitive as possible:`,
`1.	Wallet Interface: Clear sections for balances, transaction history, and deposit/withdrawal options. Icons and tooltips explain processes for first-time crypto users.`,
`2.	Deposit/Withdrawal: A two-step process with progress indicators and confirmations ensures users feel confident throughout.`,
`These decisions aimed to make crypto betting as simple as using fiat currency platforms, lowering the barrier for traditional bettors.`,
        ]}
      />

      <ImageGroup
        columns={3}
        images={["betero-8", "betero-9", "betero-10"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Scalable platform"
        paragraphs={[
          `Betero’s audience spans multiple devices, so the platform was designed to adapt seamlessly:`,
`1.	Mobile Optimization: Every element was designed for touch, from interactive bet slips to drop-down menus for odds. Action buttons are strategically placed for one-handed use.`,
`2.	Desktop Experience: Wider screens utilize split views, such as displaying live game stats on one side and the betting slip on the other, maximizing information visibility.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-11", "betero-12"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Gamification"
        paragraphs={[
          `Betero’s token ecosystem and betting rewards were incorporated into the UX:`,
`1.	Progress Tracking: Users can view token-based achievements, reward points, and personalized betting stats, fostering long-term engagement.`,
`2.	Community Features: Highlighting top-performing bettors and live leaderboard integrations adds a social, competitive element.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["betero-13"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Designing the user experience and UI for Betero, a cutting-edge betting platform, was an exhilarating and immersive journey into the world of sports and gaming. From the outset, it was clear that we were embarking on a project that demanded innovation, creativity, and a deep understanding of user behavior.”`,
        ]}
        name="Vasilije D"
        position="Designer"
      />
    </div>
  );
};

export default Betero;
