import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Creddapp = () => {
  const imageFolder = "credd";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Graphic design"]}
        imageFolder={imageFolder}
        title="Dream tracking app"
        imageName="creddlanding"
        subtitle="Credd"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `We partnered with our client to design a dream tracking app focused on providing users with an intuitive and engaging experience. The app’s purpose is to help users log, track, and analyze their dreams while creating an emotional connection through its visual and functional design. To achieve this, the project required a complete UI/UX design overhaul, along with establishing a cohesive visual identity and the core objectives included:`,
`1. Crafting a user-centered interface that simplifies the process of dream tracking and provides a seamless experience for both new and returning users. `,
`2. Designing key app features such as onboarding, login, registration, dream recording, activity tracking, and analytics.`,
`3.	Establishing a graphic identity with custom illustrations, a calming celestial-inspired theme, a unique color palette, and modern typography.`,
`4.	Creating a consistent design system to ensure scalability and smooth integration with development.`,
`5.	Delivering assets with detailed documentation, including wireframes, prototypes, and style guides, for seamless implementation.`,
`The challenge was to blend functionality with emotion, creating a space where users feel motivated to explore and engage with their dream records while maintaining clarity and usability. This project required close collaboration, creative innovation, and a focus on both the aesthetic and practical aspects of design.`,
        ]}
      />

      <ImageGroup columns={1} images={["credd-1"]} imageFolder={imageFolder} />

      <InfoSection
        title="Research"
        paragraphs={[
          `Our process began with thorough research to align the app’s functionality with user needs. We explored the target audience’s preferences, behaviors, and pain points related to dream tracking. By conducting competitor analysis and usability studies, we identified key opportunities for improvement. The insights gathered helped us shape an experience that feels intuitive and meaningful. The main objective was to make the app approachable for new users while providing advanced features for those deeply invested in dream tracking. Every design decision was rooted in understanding what users want and how they interact with similar platforms.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-2", "credd-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Creative concepting"
        paragraphs={[
          `Inspired by the mystical nature of dreams, we crafted a design concept centered around a celestial theme. Soft gradients, immersive illustrations, and calming visuals were chosen to evoke a sense of wonder and tranquility. This theme guided the overall aesthetic, ensuring that every element—from icons to animations—feels cohesive. The concept focused on blending functionality with emotion, creating an environment where users not only track their dreams but also feel inspired and connected. This creative direction set the foundation for a memorable and engaging app experience.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-4", "credd-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="UI/UX design"
        paragraphs={[
          `The user interface and experience design revolved around simplicity and clarity. We structured the app’s core screens—such as onboarding, login, dream tracking, and analytics—into clean, logical flows. Key features like activity tracking and dream recording were placed front and center for accessibility. Navigation elements were streamlined to ensure users can move effortlessly between functions. Our approach ensured that the design was not only visually appealing but also highly responsive across devices. Every micro-interaction was thoughtfully implemented to enhance usability and delight.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["credd-6", "credd-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Collaboration"
        paragraphs={[
          `Collaboration was a core element of this project. We worked closely with the client, sharing iterative designs and incorporating feedback at every stage. Our team ensured that each step, from wireframes to the final prototype, aligned with the app’s goals and vision. Once the designs were approved, we prepared comprehensive documentation and assets for development handoff, including annotated designs, style guides, and interactive prototypes. This approach ensured a smooth transition to development while maintaining consistency across all user touchpoints. The final product reflects a seamless blend of creativity, strategy, and execution.`,
        ]}
      />

      <ImageGroup columns={1} images={["credd-8"]} imageFolder={imageFolder} />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Working as a UI/UX designer on the dream tracking mobile app project was a truly exhilarating experience. It was a unique opportunity to delve into the fascinating realm of dreams and explore how technology could enhance our understanding and engagement with them.”`,
        ]}
        name="Milena S"
        position="Designer"
      />
    </div>
  );
};

export default Creddapp;
