import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";
import Hero from "../../components/hero/Hero";

const Blog6 = () => {
  const imageFolder = "blog6";
  return (
    <div className="project-template">
      <Hero
        title="How Airbnb went from zero to a $98 billion company"
        blog={"4 NOV 2024"}
        minHeight
      />
      <ImageGroup
        columns={1}
        images={["blog-1"]}
        imageFolder={imageFolder}
        onlyDesktop
        firstImage
      />

      <InfoSection
        title="Founding story and early traction"
        paragraphs={[
          `The year is 2007. Brian Chesky & Joe Gebbia are struggling to make rent on their SF apartment. Their solution? Sublet it out to renters and make money for their rent. But rather than list it on Craigslist (which seemed impersonal), they decided to build their own site & called it Air Bread and Breakfast.`,

`The product was simple - guests could stay at the apartment for a night and be greeted with breakfast in the morning. The timing couldn't have been better for Joe and Brian. There was a design conference in town, and hotel space was limited. So Joe and Brian set up a website with pictures of their loft-turned-lodging space—complete with three air mattresses on the floor and the promise of a home-cooked breakfast in the morning.`,

`They got their first three renters from this site with each one paying $80 for the night. They got emails from people around the world asking when the site would be available for destinations like Buenos Aires, London, and Japan.`,

`The following spring, they got former roommate and engineer Nathan Blecharczyk on board. They planned the launch around the Democratic National Convention in Denver when hotel spaces would be in short supply. About 80,000 people were expected to attend the conference. Two weeks before the convention, they updated their site to include a map of all the potential places attendees could get rooms.`,

`The founders needed to raise money, so in the summer of 2008, they bought & designed special edition election-themed cereal boxes called Obama O’s and Cap’n McCain’s. They raised $30k by selling these at convention parties for $40 a box.`,

`The following spring they had dinner with Paul Graham. They got into YC in 2009 and got another $20k in funding. Soon after, they secured another $600k in a seed round from Sequoia Capital and Y Ventures.`,

`Around this time, AirBnB implemented their most famous growth hack - they hacked Craiglist to grow the supply side of their marketplace. Craigslist had a massive user base. To tap into this market, Airbnb allowed users who listed properties on Airbnb to post them to Craigslist as well—despite there being no sanctioned way to do so. The founders realized that Craigslist saved listing information via a unique url. So they built a bot to visit Craigslist, capture the unique URL, input the listing info, and forward the URL to the user for publishing.`,

`Now, users could cross post their AirBnB listing to Craigslist in one click. By reposting on Craigslist, users got more eyeballs on their listing while still using AirBnB to manage & moderate inquiries.
AirBnB soon operated outside of the US, expanding to the UK, Canada & France. But there was a problem- AirBnB wasn’t gaining traction. So in 2009, Gebbia and Chesky booked spaces with 24 hosts to figure out what the problem was. Turns out, users weren’t doing a great job of presenting their listings & the photos were really bad. So the founders rented a $5000 camera & went door to door, taking professional pictures of all the listings in New York City. This increased bookings on the New York listings by 200% and increased AirBnB’s revenue in the city by 2x. They did the same thing in Paris, London, Vancouver & Miami.`
        ]}
        blog
      />

      <ImageGroup columns={1} images={["blog-2"]} imageFolder={imageFolder} onlyDesktop />

      <InfoSection
        title="Growth and scale"
        paragraphs={[
          `In 2010, the Airbnb photography program was officially launched. Hosts could schedule a professional photographer to come and take photos of their listing. This became an instant hit. This move benefited the host tremendously - they earned $1025 a month on average from just this change. By 2012, AirBnB had recruited more than 2000 freelance photographers to photograph 13,000 listings across 6 continents.
In 2011, the company integrated via Facebook & launched the Airbnb Social connections feature for users. This feature showed them the mutual Facebook connections who have stayed with or are friends with the host.`,

`They partnered with property management software providers and integrated their services into the Airbnb platform.They also integrated with third-party tools that automated booking confirmations, check-ins, and even cleaning services. Both hosts and guests benefited from integrated payment gateways and secure communication channels.`,

`The matchmaking algorithm Airbnb uses takes more than 50 aspects of guest-host matching into consideration, including variables like the property’s location, type of space, star rating, how quickly a host responds to guest enquiries, how expensive it is, etc.`,

`In May of that year, they acquired Accoleo - the German based AirBnB knockoff. The following month, Airbnb opened its office in Hamburg which was headed by Accoleo founder Gunnar Froh.
In that same year, AirBnB raised $112M at a $1 billion valuation in a Series B round from Andreessen Horowitz, Digital Sky Technologies, General Catalyst Partners, Jeff Bezos & Ashton Kutcher.`,

`But just 3 days after the funding announcement, AirBnB made headlines for the wrong reasons – an AirBnB user’s home was ransacked. AirBnB quickly made sure to ensure that such incidents are minimized & that the hosts are protected.
They doubled their customer support staff, created a dedicated Trust & Safety department, built a Host Education Center and offered insurance options to hosts. They also partnered with Lloyds of London & covered every booking with a $1,000,000 Host Guarantee. By 2011, Airbnb had reached one million bookings worldwide.`,

`The next year, in 2012, Airbnb launched the Wishlists feature. In just 4 months, over 1 million wishlists had been created with over 45% of Airbnb users using it regularly. The goal with wishlists was to enable users to choose their favorite property & share it with friends. It had the secondary effect of converting the listings on their feed into a form of content that users could engage with. In this way, their product metrics mimicked those of a social media company and they were tracking DAUs (daily active users) and MAUs (monthly active users).`,

`In 2012, the Summer Olympics were being held in London. AirBnB saw it as an opportunity to consolidate its stronghold over the European market. Their first move was to acquire their largest UK-based competitor Crashpadder. They also opened offices in London, Paris, Barcelona, and Milan.`,

`In November of that year, Airbnb launched two local initiatives—Airbnb Neighborhoods and Local Lounges. AirBnB neighborhoods allowed guests to use their interests to help them select a place to stay. This way, guests could experience the city in a more local, authentic way. They also added cleaning services by partnering with Homejoy and Handybook.`,

`In 2013, AirBnB decided to revamp their referral program, which they launched in early 2014. They did three things to really set up their referral program for success.`,
`- They measured & A/B tested invites sent via email, Twitter & Facebook to see which performed better.`,
`- They experimented with messaging and discovered that invites with a photo of the sender and keeping the copy altruistic in nature (like gifting AirBnB to a friend) resulted in higher conversions.`,
`- They also found that referrals sent via recommended contacts via Gmail resulted in a higher conversion rate.`,

`The referral program allowed hosts to invite their friends to list on Airbnb. If they did, Airbnb gave them free credits for listing their first booking on Airbnb as a guest. As soon as the new host welcomed their first Airbnb guest, the host who referred them was also given credit that they could use for their next stay at an Airbnb-listed property. When it was launched, it led to a whopping 900% of annual growth.`
        ]}
        blog
      />

      <ImageGroup
        columns={1}
        images={["blog-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Rebranding and moving upmarket"
        paragraphs={[
          `In 2014, AirBnB rebranded and created the famous AirBnB logo. That same summer, Jonathan Mildenhall was hired as the company’s Chief Marketing Officer. They wanted to invent an entire category and as Jonathan Mildenhall put it, become a “community-driven super brand.” From 2014 to 2015, advertising increased nearly 5x, to $23.5 million.`,
          `Soon after, they launched a new platform called Airbnb Trips, which offers things like experiences and services and restaurant reservations. By 2014, it had surpassed 10 million guests and 550,000 listings and had surpassed major hotel chains as the preferred one-night booking app. They were valued at $10B which made the company worth more than competitors like Hyatt and Wyndham.`,
          `That same year, they raised $475 million in a Series D round led by Sequoia Capital & Andreessen Horowitz.`,
          `They decided to move upmarket and target high-end luxury homes. So in 2018, they launched Airbnb Plus - a premium offering which provided guests with meticulously verified, high-quality homes. They also partnered with RXR Realty to develop 10 floors of Rockefeller Center into a boutique hotel-like service. They also launched Beyond by Airbnb, which featured luxury vacation rentals purchased from Luxury Retreats.
During this time, Airbnb's properties outnumbered the total number of hotel rooms in the U.S. Airbnb partnered with CWT Carlson Wagonlit Travel and American Express Global Business Travel in an attempt to expand from the leisure tourism market to the business market.
Between 2017 and 2019, Airbnb enhanced its value proposition for two specific kinds of hosts - Superhosts and hotels.`,
`Superhosts get higher visibility and exposure in guest search queries on the platform and thus have a higher likelihood of receiving bookings. Superhosts were allowed temporary editorship of the Airbnb Instagram channel one day at a time to share their stories.`,
`Airbnb also enabled hotels to find guests for their rooms on the platform via easy integration into their existing inventory management systems and without the need for a long-term contract.
Airbnb also started providing more support for professional hosts - people that manage multiple properties and do not qualify as private, permanent residences. At the end of 2019, Airbnb reported that 10% of its hosts were non-individual hosts, and 28% of its overnight stays were booked with professional hosts.
In that same year, they launched Airbnb Adventures. These included board and lodging with ticket prices ranging up to USD $5,000. They are akin to traditional holiday packages without transportation. They provide slow-food safaris, mountaineering and trekking in traditional holiday destinations such as the Galapagos Islands.
During this time, they also partnered with ThinkReservations, giving it access to 17,000 establishments in the USA alone. They also partnered with SiteMinder, a cloud-based hotel distribution platform, providing potential global access to the rooms of more than 28,000 hotels.
It also gave third-party websites such as GoEurope and Hipmunk commission to promote its brand on their channels. partnership with HotelTonight led Airbnb to cross-promote hotel room offerings across the mobile-first service and its own platform. Airbnb started making its inventory available through Google searches alongside Expedia in 2019. In selected markets, Airbnb also partnered with tourism boards – such as Visit Sweden – for marketing purposes.
They also launched the Airbnb Travel Stories, which allowed users to share personal travel stories that inspire and convey the community’s purpose.`,
`By the end of 2019, Airbnb reported 54 million active bookers, accounting for 327 million overnight stays.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="How Airbnb makes money"
        paragraphs={[
          `Airbnb is a two-sided marketplace connecting guests in search of accommodation with the hosts who rent out their place. Hosts are able to list on AirBnB for free. This has massive SEO upside.
Airbnb collects the payment online, retains it until 24 hours after guest check-in, and then pays the host. They charge 3% from the hosts and typically around 14% from the guests for every transaction made.`,
          `Airbnb’s business model relies on the volume of trades that occur on the platform, each only contributing a small profit margin. One of their metrics they optimize for is the volume of transactions that happens via AirBnB.`,
          `This is why Airbnb charges hosts a relatively low fee compared to other tourism accommodation distribution channels. The low fee attracts more hosts because they perceive Airbnb as a cost-effective distribution channel.`,
          `During peak season, bookings are commonly made 12 months in advance. Since they hold the money until after the guests check in, it leaves them with a good amount of cash to use for 12 months (almost like an interest-free loan). This model is also used by Amazon – customers order and pay, then wait for days until the items they have purchased are delivered. During this period, Amazon can work with the money.`,
          `Starting in 2019, instead of splitting the service fee between guests and hosts, hosts could now opt for a 14% host-only charge.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-5"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="How Airbnb grows"
        paragraphs={[
          `Airbnb is a location-based listing platform. With its users adding their properties, it helps them create new web pages that rank in search engines for various locations and travel-specific keywords. This has massive SEO upside.`,
          `To get the most out of SEO, AirBnB invests heavily in content. This includes blog posts, stories, and listicles that cover every stage of traveling, from planning, booking, experiencing the place, good hosting practices, etc.
The Airbnb host acts as a distribution channel for Airbnb too. Hosts use their own marketing strategies outside of Airbnb's platform to promote their properties. Many have their own Facebook business page and run ad campaigns as required.
Brian & Joe were designers so the UX would always be a key differentiator. Airbnb identified and listed out 45 moments across the entire user journey - which started at the moment a customer heard about Airbnb for the first time & ended with using AirBnB and sharing the experience with others. Their wishlist feature was executed by a new designer who replaced the " star" logo with "heart" - this small change led to 30% more conversions.`,
          `Hosts also leverage Instagram by creating photos, reels & other content around their stays. They do the same on YouTube as well. On the other hand, the guests share their vacation photos on their social media accounts, tagging the Airbnb brand pages. This helps them reach their authentic audience, hence increasing the awareness of their website.`,
          `Apart from being active on its own Instagram account, Airbnb also partners with many travel bloggers & influencers. Airbnb recognizes its users by featuring some of the tagged social posts on their own page. Many celebrities have their own vacation rental listed, and their massive social reach further adds to Airbnb's word-of-mouth efforts.`,
          `They blend SEO & UGC (User generated content) via an extensive internal linking strategy, which builds their domain authority. It has also launched its own travel magazine - Pineapple, that features travel stories and tips for both hosts and travelers.`,
          `Airbnbs YouTube marketing strategy is also on point. They also offer virtual tours under its online experiences, a feature that was an effective marketing strategy during the pandemic days. The YT videos show dreamy vacation stays and many travel tips like a typical travel YouTube channel.`,
          `Airbnb uses YouTube paid ads too & focuses their ad spend on brand-building initiatives than conversions or performance. They used ad sequencing to run tailored YouTube ads based on the type of traveler being targeted. This made their customer acquisition more personal and less sales-focused.`,
          `In terms of gross booking revenue per USD of marketing expenses, Airbnb reported USD $33, Expedia group USD $21 and Booking Holdings USD $19. Airbnb also manages to retain customers better than other online travel agents in the US with repeat guests accounting for 69% of Airbnb’s booking revenue.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Road to IPO and covid"
        paragraphs={[
          `As of 2018, Airbnb’s yearly revenue was $3.6 billion, with over 34 offices worldwide. In preparation for an IPO in 2020, the company recorded having $3.5 billion in cash, out of which, $25.9 million was raised in the most recent funding round. The total funding as of March 2020 $4.8 billion, raised across 10 funding rounds.
And then the pandemic hit.`,
          `The COVID-19 pandemic severely affected the travel industry. Airbnb faced a massive loss of $637 million, with a 67% drop in its quarterly revenue. It laid off 25% of its workforce, cut costs, and raised $2 billion in debt and equity financing. It also had to deal with regulatory issues, legal disputes, and competition from other players.`,
          `In China – the original epicenter of the pandemic, usage dropped by 80%. During this time, Airbnb faced more than $1 billion in guest cancellation requests.`,
          `In May 2020, Airbnb launched an advanced cleaning initiative with sanitation protocols and support for hosts in inspiring confidence in their guests. Hosts could not accept bookings unless they agreed to the stricter cleaning protocols. This included having a minimum of 24 hours between stays, or a more rigorous 72-hour window between departing and arriving guests. This increased confidence in the guests that adequate care was given to deep cleaning and inspired guests to emphasize on cleanliness & sanitation in their reviews.`,
          `Airbnb also updated its host knowledge resources with information about the COVID-19 pandemic and how to deal with guest health issues. By 2020, Airbnb also had over 400 agreements with local and national governments to automate the collection of tourism taxes, collecting over $2 billion in tourism-related taxes through these agreements.`,
          `Airbnb raised $3.5 billion in its IPO in December 2020, and its shares soared more than 100% on its first day of trading, valuing the company at over $100 billion. The Airbnb share value surged to a record high of 113% from $68 per share to $144 per share.`
        ]}
        blog
      />

<ImageGroup
        columns={1}
        images={["blog-7"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="7 key lessons from Airbnb"
        paragraphs={[
          `1. Start by doing things that dont scale and scratch your own itch.`,
          `2. Hustle can get you pretty far.`,
          `3. Look for hacky, non-obvious solutions to scae to your first 1000 users.`,
          `4. Experimentation is key.`,
          `5. Optimize Product & user experience.`,
          `6. If you cant beat em, buy em.`,
          `7. You can understand problems in your business just by talking to customers.`
        ]}
        blog
      />

    </div>
  );
};

export default Blog6;
