import React from "react";
import TemplateHero from "../TemplatePage/TemplateHero";
import InfoSection from "../TemplatePage/InfoSection";
import ImageGroup from "../TemplatePage/ImageGroup";

import "../TemplatePage/projectTemplate.css";

const Goatagency = () => {
  const imageFolder = "goatagency";
  return (
    <div className="project-template">
      <TemplateHero
        category={["UI/UX", "Graphic design", "Branding", "Logo", "Development"]}
        imageFolder={imageFolder}
        title="Marketing agency website and branding"
        imageName="goat"
        subtitle="Goat"
      />
      <InfoSection
        title="Project description"
        paragraphs={[
          `Our partnership with Goat Marketing Agency was driven by a deep understanding of their mission: helping businesses thrive through innovative, full-service marketing strategies. Operating within the competitive B2B space, Goat has carved out a unique position as a forward-thinking agency with a proven track record of success. With a team of 40 talented professionals, they combine agility and expertise to deliver tailored marketing solutions for businesses of all sizes.`,
`The goal of this project was to craft a brand and digital presence that not only reflected their expertise but also elevated them above their competitors. In the crowded B2B space, differentiation is critical—our approach focused on showcasing Goat’s ability to drive results while maintaining a personal, client-centric approach. This meant highlighting their innovative methodologies, such as their “Full-Funnel Approach,” alongside their deep understanding of client challenges and objectives.`,
        ]}
        buttonText="Live website"
        link="https://goat.handmade.company/index.html"
      />

      <ImageGroup
        columns={1}
        images={["goat-1"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Website design"
        paragraphs={[
          `The website redesign focused on crafting a sleek, intuitive interface that reflects Goat’s professionalism while engaging their target audience. Starting with the homepage, bold typography paired with striking visuals immediately communicates authority and innovation. Key sections, such as the “Full-Funnel Approach,” were designed to simplify complex processes into visually engaging diagrams, making Goat’s strategies easily understandable. The use of interactive navigation elements ensures seamless browsing, while the monochromatic design keeps the focus on content. Large, client-focused imagery conveys their impact, while success stories and client results build credibility and trust.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-2"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Website realization"
        paragraphs={[
          `Once the designs were finalized, we ensured a seamless transition to development. The website was built to be fully responsive, providing an optimized experience across devices. Advanced animations, such as subtle transitions and 3D effects, were implemented to enhance interactivity without compromising speed. Alongside this, we delivered a comprehensive design system, including typography, color codes, and component libraries, ensuring scalability and consistency for future updates. Goat now has a powerful digital platform and cohesive branding toolkit that positions them as a leader in the B2B marketing space.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-3"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Logo"
        paragraphs={[
          `- Typography-Driven Approach`,

`Custom Letterforms: The rounded, clean sans-serif font provides a modern, approachable look, while the slightly modified geometry of the “g” and “t” adds uniqueness and memorability.
Balance of Curves and Angles: The soft, circular “o” contrasts with the sharp, intentional cuts in the “t,” symbolizing the agency’s ability to balance creativity with strategy.`,

`- Subtle Branding Symbolism`,

`Smiling Curve: The orange curve under the “g” not only visually anchors the wordmark but also represents a smile, reinforcing positivity, client satisfaction, and success. It subtly hints at the agency’s commitment to uplifting its clients’ brands. Energy and Motion: The upward orientation of the curve conveys forward motion and growth, aligning with the agency’s mission to help businesses scale and thrive.`,

`- Subtle Branding Symbolism`,

`Smiling Curve: The orange curve under the “g” not only visually anchors the wordmark but also represents a smile, reinforcing positivity, client satisfaction, and success. It subtly hints at the agency’s commitment to uplifting its clients’ brands. Energy and Motion: The upward orientation of the curve conveys forward motion and growth, aligning with the agency’s mission to help businesses scale and thrive.`,

`- Minimalist and Versatile`,

`The design avoids unnecessary ornamentation, making it highly versatile for digital and physical applications. The clean structure ensures excellent legibility across platforms, from website headers to branded merchandise.
The lowercase typography emphasizes approachability, making the brand feel more human and accessible, in line with its client-centric ethos.`,
        ]}
      />

      <ImageGroup
        columns={2}
        images={["goat-5", "goat-4"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Branding"
        paragraphs={[
          `The branding for Goat Marketing Agency was designed to embody its innovative, forward-thinking approach to marketing. We focused on creating a clean, modern identity that communicates professionalism while reflecting the agency’s creative energy. The font Axiforma was selected as the cornerstone of the branding, offering a geometric structure that is both versatile and visually striking. Its clean lines and balanced proportions make it ideal for digital and print applications, ensuring readability and consistency across all touchpoints.

The color palette is grounded in a timeless black-and-white scheme, projecting sophistication and clarity. To balance this, we introduced a vibrant orange accent that symbolizes creativity, energy, and the agency’s ability to stand out in competitive markets. The logo design incorporates this orange in a subtle curve beneath key letters, adding a touch of playfulness and adaptability to the otherwise structured design.

Every element of the branding works together to position Goat Marketing Agency as a trusted partner in delivering innovative solutions. From the typography to the color palette, the visual identity communicates a blend of professionalism, creativity, and a results-driven mindset, ensuring the brand resonates with its clients and reflects its core mission.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-6"]}
        imageFolder={imageFolder}
        onlyDesktop
      />

      <InfoSection
        title="Awards"
        paragraphs={[
          `Goat Marketing Agency’s branding and website design earned recognition from AWWWARDS, a testament to the creativity, innovation, and strategic thinking behind the project. This prestigious platform celebrates the best in digital design globally, and receiving such accolades highlights the meticulous craftsmanship and collaborative effort that went into every detail of the project.`,
        ]}
      />

      <ImageGroup
        columns={1}
        images={["goat-7"]}
        imageFolder={imageFolder}
      />

      <InfoSection
        title="Summary"
        paragraphs={[
          `“Throughout the design process, collaboration and communication were essential. We regularly solicited feedback from the agency's team and stakeholders, iterated on the design based on their input, and ensured that everyone was aligned on the website's goals and objectives.”`,
        ]}
        name="Andrej M"
        position="Designer"
      />
    </div>
  );
};

export default Goatagency;
